import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import { applyFormat } from '../helpers';

const Indicator = ({
    countryCurrencyLocale,
    indicatorsData,
    keyName,
    title,
}) => (
    <>
        <Typography fontWeight="bold" my={2} textAlign="center" variant="body2">
            {title}
        </Typography>

        {indicatorsData?.map((tier, index) =>
            tier[keyName] ? (
                <Grid container key={index}>
                    <Grid item xs={9}>
                        <Typography variant="body2" fontWeight="bold">
                            {tier.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={9} textAlign="right">
                        {applyFormat(tier[keyName], countryCurrencyLocale)}
                    </Grid>
                </Grid>
            ) : null
        )}
    </>
);

Indicator.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    indicatorsData: PropTypes.array,
    keyName: PropTypes.string,
    title: PropTypes.string,
};

const ResponsivePeriodTable = ({ countryCurrencyLocale, indicatorsData }) => {
    const { t } = useTranslation();

    return (
        <Box mb={2} px={2} sx={{ display: { xs: 'block', md: 'none' } }}>
            <Indicator
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={indicatorsData}
                keyName="max_daily_consumption"
                title={`${t('Max daily consumption')} (kWh)`}
            />

            <Indicator
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={indicatorsData}
                keyName="max_demand"
                title={`${t('Max demand')} (kW)`}
            />

            <Indicator
                countryCurrencyLocale={countryCurrencyLocale}
                indicatorsData={indicatorsData}
                keyName="average_daily_demand"
                title={`${t('Average daily demand')} (kW)`}
            />
        </Box>
    );
};

ResponsivePeriodTable.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    indicatorsData: PropTypes.array,
};

export default ResponsivePeriodTable;
