import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Badge, Box, Button, Divider } from 'sunwise-ui';

import { ShowErrors, TitleWithDetail } from 'common/components';
import AccordionCard from 'common/components/accordions/AccordionCard';
import ProposalChartJs from 'common/components/charts/ProposalChartJs';
import { getCurrencyLocale } from 'common/utils/helpers';
import { getCountryCurrency } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import CardForm from './CardForm';
import Warnings from './Warnings';

const FormContainer = ({
    consumptionProjection,
    errors,
    handleValidateInfoConsistency,
    initialValues,
    isDisabled,
    isInfoConsistent,
    isLoading,
    offerStorageData,
    onSubmit,
    proposalConfiguration,
    solarSimulationData,
}) => {
    const [firstDay, setFirstDay] = useState();
    const [initialChartControlsConfig, setInitialChartControlsConfig] =
        useState({ days: [2], option: 3 });
    const { t } = useTranslation();
    const {
        control,
        formState: { isDirty },
        getValues,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const countryCurrency = getCountryCurrency();
    const countryCurrencyLocale = getCurrencyLocale(countryCurrency);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (!isLoading && !isEmpty(offerStorageData) && !isEmpty(initialValues))
            handleValidateInfoConsistency({
                offerStorageData,
                timeShiftingData: consumptionProjection?.dataTimeShifting,
            });
    }, [offerStorageData, initialValues, isLoading]);

    useEffect(() => {
        if (isLoading) return;

        setFirstDay(consumptionProjection?.firstDay);
    }, [isLoading, consumptionProjection?.firstDay]);

    useEffect(() => {
        if (firstDay || firstDay === 0)
            setInitialChartControlsConfig({ days: [firstDay], option: 3 });
    }, [firstDay]);

    return (
        <AccordionCard
            defaultExpanded
            customTitle={
                <TitleWithDetail variant="body2">
                    <Badge
                        badgeContent=" "
                        color="error"
                        invisible={isInfoConsistent && !isDirty}
                        variant="dot"
                    >
                        {t('Energy and economic simulation')}
                    </Badge>
                </TitleWithDetail>
            }
            isLoading={isLoading}
        >
            {(!isInfoConsistent || isDirty) && (
                <Alert
                    severity="warning"
                    sx={{ '.MuiAlert-message': { width: '100%' } }}
                >
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="space-between"
                    >
                        {t(
                            'The information in this section is not consistent with the storage configuration. Please review it'
                        )}

                        <Box
                            textAlign="right"
                            width={{ xs: '100%', md: 'auto' }}
                        >
                            <Button
                                disabled={isDisabled}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('Update')}
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            )}

            <CardForm
                control={control}
                getValues={getValues}
                initialValues={initialValues}
                isDisabled={isDisabled}
                offerStorageData={offerStorageData}
                setValue={setValue}
            />

            {errors && errors.length > 0 && <ShowErrors errors={errors} />}

            <Divider sx={{ my: 2 }} />

            <Box mx={2}>
                <Warnings
                    consumptionProjection={consumptionProjection}
                    disabled={isDisabled}
                    setInitialChartControlsConfig={
                        setInitialChartControlsConfig
                    }
                    solarSimulationData={solarSimulationData}
                />

                <ProposalChartJs
                    consumptionProjection={consumptionProjection}
                    countryCurrency={countryCurrency}
                    countryCurrencyLocale={countryCurrencyLocale}
                    initialChartControlsConfig={initialChartControlsConfig}
                    proposalConfiguration={proposalConfiguration}
                    solarSimulationData={solarSimulationData}
                />
            </Box>
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isInfoConsistent: selectors.getIsInfoConsistent,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleValidateInfoConsistency: (data) =>
        dispatch(actions.handleValidateInfoConsistency(data)),
});

FormContainer.propTypes = {
    consumptionProjection: PropTypes.object,
    errors: PropTypes.array,
    handleValidateInfoConsistency: PropTypes.func,
    initialValues: PropTypes.object,
    isInfoConsistent: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    offerStorageData: PropTypes.object,
    onSubmit: PropTypes.func,
    proposalConfiguration: PropTypes.object,
    solarSimulationData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
