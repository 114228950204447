import React from 'react';

import { TableCell, TableRow } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';

import { BASE_COLORS } from '../../constants';
import {
    getBorderColor,
    getCellInputStyle,
    getMaxInitialDate,
} from '../../helpers';

import CollapsedElements from './CollapsedElements';
import DemandCells from './DemandCells';
import EnergyCells from './EnergyCells';
import FileCell from './FileCell';
import RowAlertTooltip from './RowAlertsTooltip';

const inputVariant = 'standard';

const Row = ({
    collapsedSections,
    control,
    demandTiers,
    disabled,
    disabledConsumptionFieldsByCsv,
    energyTiers,
    finalDateDisabled,
    handleChangeKWh,
    handleDownloadLisaFile,
    handleNormalizeFinalDateBuild,
    handleNormalizeInitialDateBuild,
    handleOnChangeDailyAvg,
    handleOnChangeTotal,
    handleOnPaste,
    handleUploadFile,
    hasPowerFactor,
    isGDRate,
    isHourlyRate,
    isLastRow,
    minDate,
    name,
    period,
    periodWarnings,
    selectedRate,
    showAvgDailyField,
}) => {
    const { daysInPeriod, kW, kWh, label, predicted, url_type } = period || {};
    const basePeriodStyle = getCellInputStyle(BASE_COLORS.PERIOD);
    const borderColor = getBorderColor(!predicted);
    const sxWarning = {
        border: borderColor ? `1px solid ${borderColor}` : '',
        m: '0 !important',
        '& :before': { border: 0 },
    };

    return (
        <TableRow>
            <CollapsedElements
                collapsedItem={
                    <TableCell
                        sx={{
                            ...basePeriodStyle,
                            backgroundColor: 'background.paper',
                            borderBottomLeftRadius: isLastRow ? '10px' : 0,
                            left: 0,
                            minWidth: '100px',
                            position: 'sticky',
                            zIndex: 1,
                        }}
                    >
                        <RowAlertTooltip
                            periodWarnings={periodWarnings}
                            period={period}
                        />

                        {label}
                    </TableCell>
                }
                isCollapsed={collapsedSections?.period}
            >
                <TableCell
                    sx={{
                        ...basePeriodStyle,
                        borderBottomLeftRadius: isLastRow ? '10px' : 0,
                        minWidth: '100px',
                        width: '100px',
                    }}
                >
                    <RowAlertTooltip
                        mr={0}
                        periodWarnings={periodWarnings}
                        period={period}
                    />

                    <FileCell
                        control={control}
                        disabled={disabled}
                        handleDownloadLisaFile={handleDownloadLisaFile}
                        handleUploadFile={handleUploadFile}
                        name={`${name}.file`}
                        urlType={url_type}
                    />
                </TableCell>

                <TableCell sx={basePeriodStyle}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={disabledConsumptionFieldsByCsv}
                        maxDate={getMaxInitialDate(period)}
                        minDate={minDate}
                        name={`${name}.initial_date`}
                        onChange={(value) =>
                            handleNormalizeInitialDateBuild(value)
                        }
                        sxInput={sxWarning}
                        variant={inputVariant}
                    />
                </TableCell>

                <TableCell sx={basePeriodStyle}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={
                            finalDateDisabled || disabledConsumptionFieldsByCsv
                        }
                        maxDate={getMaxInitialDate(period)}
                        minDate={minDate}
                        name={`${name}.final_date`}
                        onChange={handleNormalizeFinalDateBuild}
                        sxInput={sxWarning}
                        variant={inputVariant}
                    />
                </TableCell>

                <TableCell align="center" sx={basePeriodStyle}>
                    {daysInPeriod || 0}
                </TableCell>
            </CollapsedElements>

            <EnergyCells
                control={control}
                disabled={disabledConsumptionFieldsByCsv}
                energyFields={kWh}
                energyTiers={energyTiers}
                handleChangeKWh={handleChangeKWh}
                handleOnChangeDailyAvg={handleOnChangeDailyAvg}
                handleOnChangeTotal={handleOnChangeTotal}
                handleOnPaste={handleOnPaste}
                hasBottomRightRadius={isLastRow && !isGDRate && !hasPowerFactor}
                inputVariant={inputVariant}
                isCollapsed={collapsedSections?.energy}
                isHourlyRate={isHourlyRate}
                name={name}
                showAvgDailyField={showAvgDailyField}
                sxWarning={sxWarning}
            />

            {isGDRate && (
                <DemandCells
                    control={control}
                    demandFields={kW}
                    demandTiers={demandTiers}
                    disabled={disabled}
                    handleOnPaste={handleOnPaste}
                    hasBottomRightRadius={isLastRow && !hasPowerFactor}
                    inputVariant={inputVariant}
                    isCollapsed={collapsedSections?.demand}
                    isHourlyRate={isHourlyRate}
                    name={name}
                    period={period}
                    selectedRate={selectedRate}
                    sxWarning={sxWarning}
                />
            )}

            {hasPowerFactor && (
                <TableCell
                    align="center"
                    sx={{
                        ...getCellInputStyle(BASE_COLORS.POWER_FACTOR),
                        borderBottomRightRadius: isLastRow ? '10px' : 0,
                    }}
                >
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        fullWidth
                        max={100}
                        min={0}
                        onPaste={(event) =>
                            handleOnPaste(event, 'power_factor')
                        }
                        name={`${name}.power_factor`}
                        sx={sxWarning}
                        variant={inputVariant}
                    />
                </TableCell>
            )}
        </TableRow>
    );
};

Row.propTypes = {
    collapsedSections: PropTypes.object,
    control: PropTypes.object,
    demandTiers: PropTypes.array,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    energyTiers: PropTypes.array,
    finalDateDisabled: PropTypes.bool,
    handleChangeKWh: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleNormalizeFinalDateBuild: PropTypes.func,
    handleNormalizeInitialDateBuild: PropTypes.func,
    handleOnChangeDailyAvg: PropTypes.func,
    handleOnChangeTotal: PropTypes.func,
    handleOnPaste: PropTypes.func,
    handleUploadFile: PropTypes.func,
    hasPowerFactor: PropTypes.bool,
    isGDRate: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    isLastRow: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    period: PropTypes.object,
    periodWarnings: PropTypes.object,
    selectedRate: PropTypes.object,
    showAvgDailyField: PropTypes.bool,
};

export default Row;
